import React from 'react'
import { toast } from 'react-toastify'
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr'
import { acquireApiAccessToken, authProvider } from 'authConfig'
import { IS_DEBUG_ON } from 'constants/common'
import consoleErrorData from 'utils/Axios/consoleErrorData'
import consoleLogData from 'utils/Axios/consoleLogData'

import {
    ContextSignalRInitialState,
    ContextSignalRProvider,
    ContextSignalRType,
    useGetSetSignalRConnectionId,
} from './ContextSignalR'

type ContextSignalRProviderProps = {
    children: React.ReactNode | React.ReactNode[]
}

const ContextSignalR = ({ children }: ContextSignalRProviderProps) => {
    const [contextState, setContextState] = React.useState<ContextSignalRType>(
        ContextSignalRInitialState
    )

    const [connection, setConnection] = React.useState<HubConnection>()

    const [newConnectionId, setNewConnectionId] = React.useState<string | null>(
        null
    )
    const [, setSignalRConnectionId] = useGetSetSignalRConnectionId()

    React.useEffect(() => {
        if (!connection) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(
                    `${process.env.REACT_APP_REFLECT_BACKEND_API}/reflectSignalR`,
                    {
                        accessTokenFactory: () =>
                            acquireApiAccessToken(authProvider),
                    }
                )
                .configureLogging(IS_DEBUG_ON ? LogLevel.Trace : LogLevel.Error)
                .build()
            setConnection(newConnection)
        }
    }, [connection])

    React.useEffect(() => {
        if (connection && connection.state !== HubConnectionState.Connected) {
            connection
                .start()
                .then(() => {
                    consoleLogData('SignalR connected!')
                    consoleLogData(connection)
                    setNewConnectionId(connection.connectionId)

                    connection.on('signalRMessage', (message: string) => {
                        consoleLogData(`signalR Message: ${message}`)
                    })

                    connection.on('successMessage', (message: string) => {
                        toast(message)
                    })

                    connection.on('failureMessage', (message: string) => {
                        toast(message)
                    })

                    connection.onreconnected(connectionId => {
                        if (connectionId) {
                            setSignalRConnectionId(connectionId)
                        }
                        consoleLogData(`SignalR reconnected: ${connectionId}`)
                    })
                })
                .catch(error => {
                    consoleErrorData(error)
                })
        }
    }, [connection, contextState, setSignalRConnectionId])

    return (
        <ContextSignalRProvider
            value={{ connectionId: newConnectionId, callback: setContextState }}
        >
            {children}
        </ContextSignalRProvider>
    )
}

export default ContextSignalR
