import { useEffect, useState } from 'react'
import reflectApiAxiosInstance from 'utils/Axios/ReflectApiAxios'
import { format, parseISO } from 'date-fns'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'

interface IReleaseInformation {
    releaseName: string
}

const ReleaseInformation = ({ releaseName }: IReleaseInformation) => {
    const [BEBuildInfo, setBEBuildInfo] = useState<IBuildInfo>()
    const [BEFormattedBuildInfo, setBEFormattedBuildInfo] = useState('')

    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller

        const getBEBuildInfo = async () => {
            try {
                const response = await reflectApiAxiosInstance.get<IBuildInfo>(
                    'api/BuildInfo/api/build',
                    { signal }
                )
                setBEBuildInfo(response.data)
            } catch (error) {
                toast.error(error)
            }
        }
        getBEBuildInfo()

        return () => {
            controller.abort()
        }
    }, [])

    const formatBuildNumber = rawBuildNumber => {
        if (rawBuildNumber) {
            const [date, version] = rawBuildNumber.split('.')
            const formattedDate = format(parseISO(date), 'dd/MM/yyyy')
            return `${formattedDate} V-${version}`
        } else {
            return 'unknown version'
        }
    }

    useEffect(() => {
        if (BEBuildInfo)
            setBEFormattedBuildInfo(formatBuildNumber(BEBuildInfo?.buildNumber))
    }, [BEBuildInfo])

    return (
        <Box
            sx={{
                padding: '1em',
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                bottom: 0,
                right: 0,
            }}
        >
            <Typography variant='caption' fontStyle='italic'>
                FE: {releaseName}
            </Typography>
            <Typography variant='caption' fontStyle='italic'>
                BE: {BEFormattedBuildInfo}
            </Typography>
        </Box>
    )
}

export default ReleaseInformation
