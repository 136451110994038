import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { acquireApiAccessTokenRoles, authProvider } from 'authConfig'

import Loader from 'components/Loader'

interface IRolesContext {
    cdsid: string
    roles: string[]
}

const RolesContext = createContext<IRolesContext>({
    roles: [],
    cdsid: '',
})

export const RolesContextProvider = ({ children }: PropsWithChildren) => {
    const [loading, setLoading] = useState(true)
    const [roles, setRoles] = useState<string[]>([])
    const [cdsid, setCdsid] = useState('')

    useEffect(() => {
        const getRoles = async () => {
            const { apiAccessTokenRoles, preferredUsername } =
                await acquireApiAccessTokenRoles(authProvider)

            setRoles(apiAccessTokenRoles)
            setCdsid(preferredUsername)
            setLoading(false)
        }

        getRoles()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <Loader text='Fetching api roles...' />
    }

    // TODO - add check for roles
    // **************************
    if (roles.length === 0) {
        return (
            <Loader
                text='You are unauthorized to access Rivendell. Please contact support.'
                error
            />
        )
    }

    return (
        <RolesContext.Provider
            value={{
                cdsid,
                roles,
            }}
        >
            {children}
        </RolesContext.Provider>
    )
}

export const useRoles = () => useContext(RolesContext)
