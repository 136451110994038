import { acquireApiAccessToken, authProvider } from 'authConfig'
import axios from 'axios'

import consoleErrorData from './consoleErrorData'
import consoleLogData from './consoleLogData'
import HttpRequestHelper from './HttpRequestHelper'

const reflectApiAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CODEV_BACKEND_API,
})

/**
 * Request interceptor for API calls
 */
reflectApiAxiosInstance.interceptors.request.use(
    async config => {
        const newConfig = config
        const accessToken = await acquireApiAccessToken(authProvider)
        newConfig.headers = {
            Authorization: `Bearer ${accessToken}`,
        }
        return newConfig
    },
    error => {
        Promise.reject(error)
    }
)

reflectApiAxiosInstance.interceptors.response.use(
    response => {
        consoleLogData(response)

        return response
    },
    error => {
        HttpRequestHelper.GetGlobalErrorMessage(
            error.response.status,
            `${error.response.config.baseURL}${error.response.config.url}`,
            error.response.data
        )

        consoleErrorData(error)

        return Promise.reject(error)
    }
)

export default reflectApiAxiosInstance
