import {
    ProviderState,
    Providers,
    SimpleProvider,
} from '@microsoft/mgt-element'
import { acquireGraphAccessToken, authProvider } from 'authConfig'

import AppAuth from 'components/AppAuth/AppAuth'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { BrowserRouter } from 'react-router-dom'
import Layout from 'components/Layout'
import { MsalProvider } from '@azure/msal-react'
import { RolesContextProvider } from 'context/RolesContext'
import Router from 'components/Router'
import { Suspense } from 'react'
import { reactPlugin } from 'appInsights'

const ApplicationLoader = () => <div>Application loading...</div>

const provider = new SimpleProvider(() => acquireGraphAccessToken(authProvider))
Providers.globalProvider = provider
Providers.globalProvider.setState(ProviderState.SignedIn)

const App = () => (
    <Suspense fallback={<ApplicationLoader />}>
        <MsalProvider instance={authProvider}>
            <BrowserRouter>
                <AppAuth>
                    <AppInsightsContext.Provider value={reactPlugin}>
                        <RolesContextProvider>
                            <Layout>
                                <Router />
                            </Layout>
                        </RolesContextProvider>
                    </AppInsightsContext.Provider>
                </AppAuth>
            </BrowserRouter>
        </MsalProvider>
    </Suspense>
)

export default App
