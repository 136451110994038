import { IS_DEBUG_ON } from 'constants/common'

/**
 * Function that will error something to the console if IS_DEBUG_ON is true
 * @param postData data in any format
 */
const consoleLogData = (postData: any) => {
    if (IS_DEBUG_ON) {
        // eslint-disable-next-line no-console
        console.log(postData)
    }
}

export default consoleLogData
