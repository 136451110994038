import AuthError from './AuthError'
import AuthLoading from './AuthLoading'
import ContextSignalR from 'context/ContextSignalRProvider'
import { PropsWithChildren } from 'react'
import RedirectAuthentication from './RedirectAuthentication'

const AppAuth = ({ children }: PropsWithChildren) => (
    <RedirectAuthentication
        errorComponent={AuthError}
        loadingComponent={AuthLoading}
    >
        <ContextSignalR>{children}</ContextSignalR>
    </RedirectAuthentication>
)

export default AppAuth
