import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import CentredFlexBox from 'components/CentredFlexBox'

const LinearProgressIndicator = ({ description }: { description?: string }) => (
    <>
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
        <CentredFlexBox>
            <Typography
                variant='caption'
                component='div'
                color='text.secondary'
            >
                {description}
            </Typography>
        </CentredFlexBox>
    </>
)

export default LinearProgressIndicator
