import { ReactNode } from 'react'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'

const CentredFlexBox = ({
    children,
    sx,
}: {
    children: ReactNode
    sx?: SxProps
}) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            ...sx,
        }}
    >
        {children}
    </Box>
)

export default CentredFlexBox
