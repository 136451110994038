import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { getPublishedNews, getUnpublishedNews } from 'api/portalNews'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import consoleErrorData from 'utils/Axios/consoleErrorData'
import reflectApiAxiosInstance from 'utils/Axios/ReflectApiAxios'

import CoDevLogo from 'components/Assets/CoDevLogo'
import LinearProgressIndicator from 'components/LinearProgressIndicator'
import NewsCard from 'components/NewsCard'
import NewsModal from 'components/NewsModal'
import ReleaseInformation from 'components/ReleaseInformation'
import { releaseName } from 'utils/envUtils'

const Home = () => {
    const [loading, setLoading] = useState(true)
    const [news, setNews] = useState<News[]>([])
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(-1)
    const [showPublished, setShowPublished] = useState(true)

    const getData = useCallback(async () => {
        try {
            if (showPublished) {
                const newsResponse = await getPublishedNews()
                setNews(newsResponse)
            } else {
                const newsResponse = await getUnpublishedNews()
                setNews(newsResponse)
            }
        } catch (error) {
            consoleErrorData(error)
        } finally {
            setLoading(false)
        }
    }, [showPublished])

    useEffect(() => {
        getData()
    }, [getData])

    const onModalClose = async (
        title,
        category,
        text,
        introduction,
        image,
        thumbnail,
        publishDate,
        unPublishDate
    ) => {
        setLoading(true)
        try {
            if (selected >= 0) {
                await reflectApiAxiosInstance.patch(
                    `/api/PortalNews/${selected}`,
                    [
                        {
                            OperationType: 'Add',
                            path: 'title',
                            value: title,
                        },
                        {
                            OperationType: 'Add',
                            path: 'category',
                            value: category,
                        },
                        {
                            OperationType: 'Add',
                            path: 'text',
                            value: text,
                        },
                        {
                            OperationType: 'Add',
                            path: 'introduction',
                            value: introduction,
                        },
                        {
                            OperationType: 'Add',
                            path: 'imageUrl',
                            value: image,
                        },
                        {
                            OperationType: 'Add',
                            path: 'thumbnailUrl',
                            value: thumbnail,
                        },
                        {
                            OperationType: 'Add',
                            path: 'publishDate',
                            value: publishDate,
                        },
                        {
                            OperationType: 'Add',
                            path: 'unPublishDate',
                            value: unPublishDate,
                        },
                    ]
                )
            } else {
                await reflectApiAxiosInstance.post('/api/PortalNews', {
                    Title: title,
                    Category: category,
                    Text: text,
                    Introduction: introduction,
                    ImageUrl: image,
                    ThumbnailUrl: thumbnail,
                    PublishDate: publishDate,
                    UnPublishDate: unPublishDate,
                })
            }
        } catch (error) {
            toast.error(error)
        } finally {
            getData()
            setOpen(false)
            setSelected(-1)
        }
    }

    return (
        <Box sx={{ flexGrow: 1, position: 'relative', minHeight: '100vh' }}>
            <AppBar position='static'>
                <Toolbar>
                    <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ mr: 2 }}
                    >
                        <CoDevLogo />
                    </IconButton>
                    <Typography
                        variant='h6'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    >
                        Rivendell
                    </Typography>
                    <Button color='inherit' onClick={() => setOpen(true)}>
                        Create News
                    </Button>
                    <Button
                        color='inherit'
                        onClick={() => setShowPublished(status => !status)}
                    >
                        {`Show ${showPublished ? 'Unpublished' : 'Published'}`}
                    </Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ flexGrow: 1, padding: '1em' }}>
                {loading ? (
                    <LinearProgressIndicator description='Fetching News' />
                ) : (
                    <Grid container spacing={2}>
                        {news.map(n => (
                            <Grid key={n.id} item xs={4}>
                                <NewsCard
                                    news={n}
                                    setOpen={setOpen}
                                    setSelected={setSelected}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
            <ReleaseInformation releaseName={releaseName}></ReleaseInformation>
            <NewsModal
                open={open}
                setOpen={setOpen}
                news={news}
                selected={selected}
                setSelected={setSelected}
                callback={onModalClose}
            />
        </Box>
    )
}

export default Home
