import { Configuration, PublicClientApplication } from '@azure/msal-browser'

import consoleLogData from 'utils/Axios/consoleLogData'
import parseJwt from 'utils/parseJwt'

export const authConfig: Configuration = {
    auth: {
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
}

export const graphScopes = [
    'User.Read.All',
    'User.ReadBasic.All',
    'User.Read',
    'People.Read',
]

export const acquireGraphAccessToken = async (
    msalInstance: PublicClientApplication
) => {
    const account = msalInstance.getAllAccounts()[0]

    if (!account) {
        /**
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        throw Error('No active account! Verify a user has been signed in.')
    }
    const request = {
        scopes: graphScopes,
        account,
    }

    const authResult = await msalInstance.acquireTokenSilent(request)

    return authResult.accessToken
}

export const apiScopes = [process.env.REACT_APP_REFLECT_BACKEND_API_SCOPE ?? '']

export const acquireApiAccessToken = async (
    msalInstance: PublicClientApplication
) => {
    const account = msalInstance.getAllAccounts()[0]

    if (!account) {
        /**
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        throw Error('No active account! Verify a user has been signed in.')
    }

    const request = {
        scopes: apiScopes,
        account,
    }

    const authResult = await msalInstance.acquireTokenSilent(request)

    const apiAccessTokenRoles = parseJwt(authResult.accessToken).roles
    consoleLogData(apiAccessTokenRoles)

    return authResult.accessToken
}

export const acquireApiAccessTokenRoles = async (
    msalInstance: PublicClientApplication
) => {
    const account = msalInstance.getAllAccounts()[0]

    if (!account) {
        /**
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        throw Error('No active account! Verify a user has been signed in.')
    }
    const request = { scopes: apiScopes, account }
    const authResult = await msalInstance.acquireTokenSilent(request)
    const parsedAccessToken = parseJwt(authResult.accessToken)
    const apiAccessTokenRoles = parsedAccessToken.roles
    const preferredUsername = parsedAccessToken.preferred_username.split('@')[0]

    return { apiAccessTokenRoles, preferredUsername }
}

export const authProvider = new PublicClientApplication(authConfig)
