import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Avatar, Stack, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { postUploadImage } from 'api/portalNews'

import LinearProgressIndicator from 'components/LinearProgressIndicator'

interface IMediaUploader {
    title: string
    callback: (url: string) => void
    media?: string
}

const MediaUploader = ({ title, callback, media }: IMediaUploader) => {
    const [image, setImage] = useState<{ name: string; url: string }>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (image?.url) {
            callback(image.url)
        }
    }, [image, callback])

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files[0]) {
            return
        }

        setLoading(true)
        try {
            const formData = new FormData()
            formData.append(
                'image',
                event.target.files[0],
                event.target.files[0].name
            )

            const { data } = await postUploadImage(formData)

            setImage({ name: event.target.files[0].name, url: data })
        } catch (error) {
            toast.error(String(error))
        } finally {
            setLoading(false)
        }
    }

    const clearImage = () => {
        setImage(undefined)
        callback('')
    }

    if (loading) {
        return <LinearProgressIndicator />
    }

    const id = title.replace(' ', '').toLowerCase()

    return (
        <>
            <input
                style={{ display: 'none' }}
                id={`media-upload-${id}`}
                type='file'
                accept='image/*'
                onChange={handleFileChange}
            />
            <Stack direction='column' spacing={2}>
                <label htmlFor={`media-upload-${id}`}>
                    <Button
                        variant='contained'
                        color='primary'
                        component='span'
                        disabled={loading}
                    >
                        {title}
                    </Button>
                </label>
                {(image || media) && (
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Tooltip title='Remove' placement='right'>
                            <Button size='small'>
                                <Avatar
                                    alt='thumbnail'
                                    variant='square'
                                    src={image?.url || media}
                                    onClick={clearImage}
                                />
                            </Button>
                        </Tooltip>
                        <Typography fontSize={12} fontStyle='italic'>
                            {image?.name}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </>
    )
}

export default MediaUploader
