import reflectApiAxiosInstance from 'utils/Axios/ReflectApiAxios'
import { fetchAPI } from './genericFunctions'

export const getPublishedNews = async () => {
    const today = new Date().toISOString()
    const response = await fetchAPI<News>(
        `api/PortalNews?&filter=(PublishDate le ${today} or PublishDate eq null) and 
        (UnPublishDate gt ${today} or UnPublishDate eq null)`
    )

    return response
}

export const getUnpublishedNews = async () => {
    const today = new Date().toISOString()
    const response = await fetchAPI<News>(
        `api/PortalNews?&filter=(PublishDate gt ${today}) or 
        (UnPublishDate le ${today})`
    )

    return response
}

export const postUploadImage = async (formData: FormData) => {
    return reflectApiAxiosInstance.post('/api/PortalNews/UploadImage', formData)
}
