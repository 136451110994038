import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { isDayjs } from 'dayjs'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'

import MediaUploader from './MediaUploader'

import 'react-quill/dist/quill.snow.css'

const today = dayjs()

interface INewsModal {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    news: News[]
    selected: number
    setSelected: Dispatch<SetStateAction<number>>
    callback: (
        _title,
        _category,
        _text,
        _introduction,
        _image,
        _thumbnail,
        _publishDate,
        _unPublishDate
    ) => void
}

const NewsModal = ({
    open,
    setOpen,
    news,
    selected,
    setSelected,
    callback,
}: INewsModal) => {
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')
    const [introduction, setIntroduction] = useState('')
    const [text, setText] = useState('')
    const [image, setImage] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [publishDate, setPublishDate] = useState(today)
    const [unPublishDate, setUnPublishDate] = useState<dayjs.Dayjs>()

    useEffect(() => {
        if (open) {
            if (selected > 0) {
                const n = news.filter(a => a.id === selected)[0]

                setTitle(n.title || '')
                setCategory(n.category || '')
                setIntroduction(n.introduction || '')
                setText(n.text || '')
                setImage(n.imageUrl || '')
                setThumbnail(n.thumbnailUrl || '')
                n.publishDate === null
                    ? setPublishDate(null)
                    : setPublishDate(dayjs(n.publishDate))

                n.unPublishDate === null
                    ? setUnPublishDate(null)
                    : setUnPublishDate(dayjs(n.unPublishDate))
            } else {
                setTitle('')
                setCategory('')
                setIntroduction('')
                setText('')
                setImage('')
                setThumbnail('')
                setPublishDate(today)
                setUnPublishDate(undefined)
            }
        }
    }, [open, selected, news])

    const handleClose = () => {
        setOpen(false)
        setSelected(-1)
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={false}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                {selected > 0 ? 'Edit News' : 'Create News'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <TextField
                            label='Category'
                            value={category}
                            onChange={e => setCategory(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <TextField
                            label='Title'
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Introduction'
                            fullWidth={true}
                            value={introduction}
                            onChange={e => setIntroduction(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReactQuill
                            theme='snow'
                            value={text}
                            onChange={setText}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MediaUploader
                            title='Upload Image'
                            callback={setImage}
                            media={image}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MediaUploader
                            title='Upload Thumbnail'
                            callback={setThumbnail}
                            media={thumbnail}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label='Publish Date'
                                value={publishDate}
                                onChange={newValue => {
                                    setPublishDate(newValue)
                                }}
                                renderInput={params => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label='UnPublish Date'
                                value={unPublishDate}
                                minDate={publishDate}
                                onChange={newValue => {
                                    setUnPublishDate(newValue)
                                }}
                                renderInput={params => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() =>
                        callback(
                            title,
                            category,
                            text,
                            introduction,
                            image,
                            thumbnail,
                            isDayjs(publishDate)
                                ? String(publishDate)
                                : undefined,
                            isDayjs(unPublishDate)
                                ? String(unPublishDate)
                                : undefined
                        )
                    }
                    disabled={
                        !title ||
                        !category ||
                        !text ||
                        (unPublishDate !== undefined &&
                            unPublishDate?.isBefore(publishDate, 'day'))
                    }
                >
                    {selected > 0 ? 'Edit' : 'Create'}
                </Button>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewsModal
