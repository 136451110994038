import { MsalAuthenticationResult } from '@azure/msal-react'

const AuthError = ({ error }: MsalAuthenticationResult) => (
    <div className='message-container'>
        <div className='message-header'>Sign in</div>
        <div className='nessage-body'>
            <p>Sorry, but we’re having trouble signing you in.</p>
            {/* eslint-disable-next-line quotes */}
            {error?.message.replace("'{EmailHidden}'", '')}
        </div>
    </div>
)

export default AuthError
