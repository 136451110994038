import { toast } from 'react-toastify'
import { sanitizeInput } from 'utils/sanitizers'

import AxiosErrorEnum from './AxiosErrorEnum'

const HttpRequestHelper = {
    GetGlobalErrorMessage: (
        errorNumber: number | undefined = undefined,
        url: string | undefined = undefined,
        message: string | undefined = undefined
    ) => {
        const sanitizedErrorNumber = sanitizeInput(errorNumber)
        const sanitizedUrl = sanitizeInput(url)
        const santizedMessage = sanitizeInput(message)
        switch (errorNumber) {
            case AxiosErrorEnum.NotAuthorized:
                toast(sanitizedErrorNumber)
                break
            case AxiosErrorEnum.Forbidden:
                toast(`
                    ${sanitizedErrorNumber}
                    Your user role does not allow usage of this function.
                    The server responded that your connection is forbidden.
                    Please contact technical support.
                `)
                break
            case AxiosErrorEnum.NotFound:
                toast(`
                    ${sanitizedErrorNumber}
                    The requested endpoint
                    ${sanitizedUrl ? `(${sanitizedUrl}) ` : ''}
                    was not found. Please contact technical support.
                `)
                break
            case AxiosErrorEnum.UnknownBackendError:
                toast(`
                    ${sanitizedErrorNumber}
                    An unknown error occured in the backend while processing your request.
                    Please contact technical support.
                `)
                break
            case AxiosErrorEnum.BadRequest:
                toast(`
                    ${sanitizedErrorNumber}
                    ${santizedMessage}
                `)
                break
            default:
                toast(`
                    ${sanitizedErrorNumber ? `(${sanitizedErrorNumber}) ` : ''}
                    An unknown error occured while processing your request.
                    Please contact technical support.
                `)
                break
        }
    },
}

export default HttpRequestHelper
