import styled from '@emotion/styled'
import { ReactComponent as Logo } from 'assets/CoDev-Logo.svg'

const StyledLogo = styled(Logo)`
    max-height: 100%;
`

const CoDevLogo = () => <StyledLogo />

export default CoDevLogo
