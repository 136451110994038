import { ReactNode } from 'react'
import { ToastContainer } from 'react-toastify'
import CssBaseline from '@mui/material/CssBaseline'

import 'react-toastify/dist/ReactToastify.css'

interface ILayout {
    children: ReactNode
}

const Layout = ({ children }: ILayout) => {
    return (
        <>
            <CssBaseline />
            {children}
            <ToastContainer
                position='bottom-center'
                autoClose={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
            />
        </>
    )
}

export default Layout
