import React, { createContext, useContext } from 'react'
import { HubConnection } from '@microsoft/signalr'

export type ContextSignalRType = {
    callback?: React.Dispatch<React.SetStateAction<ContextSignalRType>>
} & Pick<HubConnection, 'connectionId'>

export const ContextSignalRInitialState: ContextSignalRType = {
    connectionId: null,
}

const ContextSignalR = createContext<ContextSignalRType>(
    ContextSignalRInitialState
)

export const ContextSignalRProvider = ContextSignalR.Provider
export const ContextSignalRConsumer = ContextSignalR.Consumer

export const useGetSignalRConnectionId = () => {
    const context = useContext(ContextSignalR)
    if (context === undefined) {
        throw new Error(
            'useGetSignalRConnectionId must be used within a ContextSignalRProvider'
        )
    }
    return context.connectionId
}

export const useGetSetSignalRConnectionId = (): [
    string | null,
    (signalRConnectionId: string) => void
] => {
    const context = useContext(ContextSignalR)
    if (context === undefined) {
        throw new Error(
            'useGetSetSignalRConnectionId must be used within a ContextSignalRProvider'
        )
    }
    const setSignalRConnectionId = (signalRConnectionId: string) => {
        if (context.callback) {
            context.callback({
                ...context,
                connectionId: signalRConnectionId,
            })
        }
    }
    return [context.connectionId, setSignalRConnectionId]
}
