/* eslint-disable check-file/no-index */

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from 'App'

const container = document.getElementById('app')

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
    <StrictMode>
        <App />
    </StrictMode>
)
