import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory({})
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATIONKEY,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        loggingLevelTelemetry: 2,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
})

appInsights.loadAppInsights()

export { appInsights, reactPlugin }
