/* eslint-disable no-shadow */

enum AxiosErrorEnum {
    NotAuthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    RequestTimeout = 408,
    UnknownBackendError = 500,
    BadRequest = 400,
}

export default AxiosErrorEnum
