import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import CentredFlexBox from 'components/CentredFlexBox'

const AuthLoading = () => (
    <CentredFlexBox
        sx={{
            margin: '5em',
            flexDirection: 'column',
        }}
    >
        <CentredFlexBox>
            <CircularProgress />
        </CentredFlexBox>
        <CentredFlexBox
            sx={{
                margin: '1em',
            }}
        >
            <Typography
                variant='caption'
                component='div'
                color='text.secondary'
            >
                Signing in...
            </Typography>
        </CentredFlexBox>
    </CentredFlexBox>
)

export default AuthLoading
