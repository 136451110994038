import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Dispatch, SetStateAction } from 'react'

interface INewsCard {
    news: News
    setOpen: Dispatch<SetStateAction<boolean>>
    setSelected: Dispatch<SetStateAction<number>>
}

const NewsCard = ({ news, setOpen, setSelected }: INewsCard) => {
    const { id, title, category, introduction, imageUrl, text, thumbnailUrl } =
        news

    return (
        <Card sx={{ flex: 1 }}>
            {(imageUrl || thumbnailUrl) && (
                <CardMedia
                    component='img'
                    height='140'
                    image={imageUrl || thumbnailUrl}
                    alt={title}
                />
            )}
            <CardContent>
                <Typography gutterBottom component='div'>
                    {category}
                </Typography>
                <Typography gutterBottom variant='h4' component='div'>
                    {title}
                </Typography>
                <Typography
                    variant='body1'
                    component='div'
                    sx={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: introduction }} />
                </Typography>

                <div dangerouslySetInnerHTML={{ __html: text }} />
            </CardContent>
            <CardActions>
                <Button
                    size='small'
                    onClick={() => {
                        setOpen(true)
                        setSelected(id)
                    }}
                >
                    Edit
                </Button>
            </CardActions>
        </Card>
    )
}

export default NewsCard
