import { lowerCaseKeys } from 'utils/objectManipulation'
import reflectApiAxiosInstance from 'utils/Axios/ReflectApiAxios'

const transformResponse = <T extends object>(response?: T[] | T) =>
    Array.isArray(response)
        ? response.map(r => lowerCaseKeys(r))
        : [lowerCaseKeys(response)]

export const fetchAPI = <T extends object>(route: string) =>
    new Promise<UncapitalizedObjectKeys<T>[]>((resolve, reject) => {
        reflectApiAxiosInstance
            .get(route)
            .then(response => {
                resolve(transformResponse(response.data))
            })
            .catch(error => {
                // consoleErrorData(error)
                reject(error)
            })
    })

export const fetchOdataAPI = <T extends object>(route: string, options = '') =>
    new Promise<{ count: number; data: UncapitalizedObjectKeys<T>[] }>(
        (resolve, reject) => {
            reflectApiAxiosInstance
                .get(`${route}${options}`)
                .then(response => {
                    resolve({
                        count:
                            response.data['@odata.count'] ??
                            response.data.value.length,
                        data: transformResponse(response.data.value),
                    })
                })
                .catch(error => {
                    //  consoleErrorData(error)
                    reject(error)
                })
        }
    )

export const postAPI = <T extends object>(
    route: string,
    data:
        | Partial<UncapitalizedObjectKeys<T>>
        | Partial<UncapitalizedObjectKeys<T>>[]
) =>
    new Promise<UncapitalizedObjectKeys<T>>((resolve, reject) => {
        reflectApiAxiosInstance
            .post(route, data)
            .then(response => {
                resolve(lowerCaseKeys(response.data))
            })
            .catch(error => {
                // consoleErrorData(error)
                reject(error)
            })
    })

export const putAPI = async <T extends object>(
    route: string,
    data:
        | Partial<UncapitalizedObjectKeys<T>>
        | Partial<UncapitalizedObjectKeys<T>>[]
) =>
    new Promise<UncapitalizedObjectKeys<T>>((resolve, reject) => {
        reflectApiAxiosInstance
            .put(route, data)
            .then(response => {
                resolve(lowerCaseKeys(response.data))
            })
            .catch(error => {
                // consoleErrorData(error)
                reject(error)
            })
    })

export const deleteAPI = <T extends object>(route: string) =>
    new Promise<UncapitalizedObjectKeys<T>>((resolve, reject) => {
        reflectApiAxiosInstance
            .delete(route)
            .then(response => {
                resolve(lowerCaseKeys(response.data))
            })
            .catch(error => {
                // consoleErrorData(error)
                reject(error)
            })
    })
