import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import CentredFlexBox from 'components/CentredFlexBox'

interface IAuthLoading {
    text?: string
    error?: boolean
}

const Loader = ({ text = '', error = false }: IAuthLoading) => (
    <CentredFlexBox
        sx={{
            margin: '5em',
            flexDirection: 'column',
        }}
    >
        <CentredFlexBox>
            {error ? <ErrorOutlineIcon color='error' /> : <CircularProgress />}
        </CentredFlexBox>
        <CentredFlexBox
            sx={{
                margin: '1em',
            }}
        >
            <Typography
                variant='caption'
                component='div'
                color='text.secondary'
            >
                {text}
            </Typography>
        </CentredFlexBox>
    </CentredFlexBox>
)

export default Loader
