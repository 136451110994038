/* eslint-disable no-use-before-define,indent */

import DOMPurify from 'dompurify'

/**
 * Recieve dirty input (string, array, etc), sanitize it using DOMPurify and return the sanitized <thing>
 * @param dirtyInput the string, array or similar.
 * @param domPurifyConfig config for DOMPurify, true for sensible default, false or undefined for DOMPurify default config
 * @returns santized <T>
 */
export const sanitizeString = (
    dirtyInput: string,
    domPurifyConfig: DOMPurify.Config
) => {
    const sanitizedString = DOMPurify.sanitize(dirtyInput, domPurifyConfig)
    return sanitizedString
}

/**
 * Recieve dirty input (string, array, etc), sanitize it using DOMPurify and return the sanitized <thing>
 * @param dirtyInput the string, array or similar.
 * @param domPurifyConfig config for DOMPurify, true for sensible default, false or undefined for DOMPurify default config
 * @returns santized <T>
 */
export const sanitizeInput = <T>(
    dirtyInput: T,
    domPurifyConfig: DOMPurify.Config | boolean = true
): T => {
    if (dirtyInput === null || typeof dirtyInput === 'undefined') {
        return dirtyInput
    }
    const DOMPurifyConfig = domPurifyConfig
        ? typeof domPurifyConfig === 'boolean'
            ? { ALLOWED_TAGS: ['#text'], KEEP_CONTENT: true }
            : domPurifyConfig
        : {}
    if (typeof dirtyInput === 'string') {
        const sanitizedString = sanitizeString(dirtyInput, DOMPurifyConfig)
        return sanitizedString as unknown as T
    }
    if (Array.isArray(dirtyInput)) {
        const sanitizedArray = sanitizeArray(dirtyInput, DOMPurifyConfig)
        return sanitizedArray as unknown as T
    }
    if (typeof dirtyInput === 'object') {
        const sanitizedObject = sanitizeObject(dirtyInput, DOMPurifyConfig)
        return sanitizedObject
    }
    return dirtyInput
}

/**
 * Recieve and object, sanitize all key value pairs in it
 * @param dirtyObject the dirty object
 * @param domPurifyConfig config for DOMPurify, true for sensible default, false or undefined for DOMPurify default config
 * @returns a sanitized object
 */
export const sanitizeObject = <T>(
    dirtyObject: T,
    domPurifyConfig: DOMPurify.Config
): T =>
    Object.entries(dirtyObject).reduce(
        (attrs, entry) => ({
            ...attrs,
            [entry[0]]: sanitizeInput(entry[1], domPurifyConfig),
        }),
        {} as T
    )

/**
 * Recieve dirty input (string, array, etc), sanitize it using DOMPurify and return the sanitized <thing>
 * @param dirtyArray the array.
 * @param domPurifyConfig config for DOMPurify, true for sensible default, false or undefined for DOMPurify default config
 * @returns santized <T>
 */
export const sanitizeArray = (
    dirtyArray: Array<any>,
    domPurifyConfig: DOMPurify.Config
) => {
    const sanitizedArray = dirtyArray
    sanitizedArray.forEach((value, index) => {
        if (Array.isArray(value)) {
            sanitizedArray[index] = sanitizeArray(value, domPurifyConfig)
        }
        if (typeof value === 'string') {
            sanitizedArray[index] = sanitizeString(value, domPurifyConfig)
        }
        if (typeof value === 'object' && value !== null) {
            sanitizedArray[index] = sanitizeObject(value, domPurifyConfig)
        }
    })
    return sanitizedArray
}
