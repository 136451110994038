import { InteractionType } from '@azure/msal-browser'
import {
    MsalAuthenticationProps,
    MsalAuthenticationTemplate,
} from '@azure/msal-react'

type Props = Partial<MsalAuthenticationProps>

/**
 * Attempts to authenticate user if not already authenticated, then renders child components
 */
const RedirectAuthentication = ({ children, ...rest }: Props) => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            {...rest}
        >
            {children}
        </MsalAuthenticationTemplate>
    )
}

export default RedirectAuthentication
